import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import _ from "lodash";
import constants from "../api/constants";
import apolloClient from "../graphql/client";

export default function useLoginLink() {
  console.log("Loading useLoginLink");
  // Prepare the location and history var
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  let token = searchParams.get("token");

  const login = async (token) => {
    console.log("Login...");
    const response = await fetch(constants.API_URL + "/auth", {
      credentials: "include",
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    console.log("Login done");

    try {
      await apolloClient.resetStore();
      /* await apolloClient.refetchQueries({
        include: ["user"],
        onQueryUpdated: () => {
          console.log("refetch done");
          //navigate("./");
          //window.location.reload(true);
          //
        },
      }); */
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  React.useEffect(() => {
    // Store the token for the GraphQL client
    console.log("Found token", token);
    if (!_.isEmpty(token)) login(token);
  }, [token]);

  return true;
}
